<template>
  <VendorsList />
</template>

<script>
  export default {
    components: {
      VendorsList: () => import('@/components/stock/vendors/VendorsList')
    }
  }
</script>